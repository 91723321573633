<template>
  <div class="list-manage contract-manage">
    <section class="list-manage-table">
      <div class="table-title"><span class="title">合同管理</span></div>
      <div class="contract-manage-main" v-loading="loading">
        <template v-if="list.length != 0">
          <div class="contract-manage-item" v-for="(item,index) in list" :key="index">
            <div class="f-flex-full"><i class="f-icon icon-file"></i>{{item.contract_name}}<span class="date">{{item.create_time}}</span></div>
            <span class="btn" @click="$router.push({path:'/personalCenter/contractDetails',query:{id:item.contract_id}}).catch(()=>{})">查看</span>
          </div>
        </template>
        <div v-else-if="list.length === 0 && !loading"  style="text-align: center;height: 40px;line-height: 40px;font-size: 18px;">暂无数据</div>
      </div>
    </section>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :total="total.totalRow"
        :current-page="page.pageNumber"
        :page-size="page.pageSize"
        @size-change="handleSizeChange" 
        @current-change="handleCurrentChange" >
      </el-pagination>
    </div>
  </div>
</template>

<script>
 import {con_list} from '@/api/contract'
  export default {
    data() {
      return {
        page: {
          pageNumber: 1, 
          pageSize: 10,
        },
        total:{
          totalPage:null,
          totalRow:null
        },
        list:[],
        time:null,
        loading:true
      };
    },
    created(){
      this.getList();
    },
    methods: {
      getList(){
        con_list({inputs:{}, pagination:this.page})
        .then(res => {
          this.list = res.data.list
          this.total.totalPage = res.data.totalPage
          this.total.totalRow = res.data.totalRow
          //返回时间切割
          // this.time = this.list[0].create_time.split(" ")
          this.loading = false
        })
        .catch((err) => {
        // 出错时要做的事情
        console.log(err);
      });
      },
      handleSizeChange(val) {
        this.page.pageSize = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.page.pageNumber = val
        this.getList()
      },
    }
  }
</script>

<style scoped>
</style>
